import React, { useEffect, useState } from 'react'
import SimpleHeader from "components/Headers/SimpleHeader.js";
import { Nav, NavItem, Card, CardBody, Container, Row, Col, Media, CardHeader, ListGroupItem, ListGroup, UncontrolledTooltip, DropdownToggle, DropdownMenu, DropdownItem, UncontrolledDropdown } from "reactstrap";
import { SelectAutocomplete, FormAutocomplete } from '_components/FormElements/FormInput';
import Moment from 'moment';
import { Modal, Button } from "reactstrap";
import axios from 'axios';
import { ModalSpinner, Spinner } from '../../Hoc/Spinner'
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider from "react-bootstrap-table2-toolkit";
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { useForm } from "react-hook-form";
import TablePagination from '@material-ui/core/TablePagination';
import { TablePaginationActions } from 'views/Hoc/PaginationActions';
import { SplitPaymentView } from 'views/Hoc/Dialog/SplitPaymentView';
import Chip from '@mui/material/Chip';
import Dialog from 'views/Hoc/Dialog/GlobalDialog';

export const EventPayment = (props) => {
  
  let eventId = props.location?.state?.eventId ? props.location?.state?.eventId : 0;
  const { control } = useForm({ mode: 'onBlur' });
  let [token, settoken] = useState(null);
  let seller = [{ id: 2, name: 'VENDOR', status: false }, { id: 3, name: 'DISTRIBUTOR', status: false }, { id: 4, name: 'RETAILER', status: false }, { id: 5, name: 'RESELLER', status: false }];
  let buyer = [{ id: 2, name: 'VENDOR', status: false }, { id: 3, name: 'DISTRIBUTOR', status: false }, { id: 4, name: 'RETAILER', status: false }, { id: 5, name: 'RESELLER', status: false }];

  const [channelPackIds, setChannelPack] = useState([]);
  const [state, setState] = useState({
    eventId: '',
    eventCatalystId: null,
    eventName: '',
    eventDescription: '',
    fromDate: null,
    toDate: null,
    fromTime: null,
    toTime: null,
    timeZone: '',
    address: '',
    country: [],
    city: '',
    zipCode: '',
    type: '1',
    eventLogo: '',
    eventLogoId: '',
    eventBanner: '',
    eventBannerId: '',
    sellerOption: seller,
    buyerOption: buyer,
    countryOptions: [],
    buyerPackages: [],
    sellerPackages: [],
    deletedPackages: [],
    vendorStatus: null,
    buyerStatus: null,

    eventParticipants: [],
    eventChannels: [],
    master: [],
    channelTypeCount: [],
    usersCount1: {
      "2": { "channelType": '2', "approved": 0, "not_approved": 0 },
      "3": { "channelType": '3', "approved": 0, "not_approved": 0 },
      "4": { "channelType": '4', "approved": 0, "not_approved": 0 },
      "5": { "channelType": '5', "approved": 0, "not_approved": 0 },
    },
    usersCount: []
  });

  const [loading, setLoading] = useState(false);

  const [splitFlag, setSplitFlag] = useState(false);
  const [splitData, setSplitData] = useState(false);
  const[bookHotelModal, setBookHotelModal] = useState(false);
  const[hotelName, setHotelName] = useState([]);
  const[room, setRoom] = useState([]);
  const[selectHotel, setSelectHotel] = useState(null);
  const[selectRoom, setSelectRoom] = useState(null);
  const[emptyComment, setEmptyComment] = useState(false);
  const[loading3, setLoading3] = useState(false);
  const[event_ID, setEventId] = useState(null);
  const[eventDatesArray, setEventDatesArray] = useState([]);
  const[dateLabel, setDateLabel] = useState([])
  const[userID, setUserID] = useState(null)
  const[contactID, setContactID] = useState(null)
  const[hotelBookByUser, setHotelBookByUsers] = useState([]);
  const[updateHotelFlag, setUpdateHotelFlag] = useState(false)
  let [isModalPopup2, setModalPopup2] = useState(false);

  const [hotelCancelUserId, setHotelCancelUserId] = useState(null)
  const[lastBookingDate, setLastBookingDate] = useState(null);
  const[UpdateAndCancelLastDate, setUpdateAndCancelLastDate] = useState(null);
  const[roomTypeImage, setRoomTypeImage] = useState([])
  const[roomTypeImageIndex, setRoomTypeImageIndex] = useState(0);
  const[roomTypeImageModal,setRoomTypeImageModal] = useState(false);
  const[imageItem,setImageItem] = useState([])
  const [SelectedRoom_Id, setSelectedRoom_ID] = useState(null);
  const[roomNotAvaliable, setRoomNotAvaliable] = useState(false);
  const[splitErr, SetSplitErr] = useState(false);

  const closeModalPopup2 = ()=>{
    setModalPopup2(false);
  }  
  const closeMessagePopup = () => {
    setValidationAlert(false)
  }  
  const [searchState, setSearchState] = useState({
    dropdownList: [],
    search: null,
    filterEvents:[],
    eventId:null
  });

  let [channelType, setChannelType] = useState([]);
  let [paymentStatus, setPaymentStatus] = useState(null);
  let [catalystStatus, setCatalystStatus] = useState(null);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null, fromEvent: null });
  let [open, setModalOpen] = useState(false);
  const[validationAlert, setValidationAlert] = useState(false);
  var userData = JSON.parse(localStorage.getItem('CHuser'));
   
  const closeModal = ()=>{
    setSelectHotel(null)
    setSelectRoom(null)
    setBookHotelModal(false)
    setUpdateHotelFlag(false)
    setRoomTypeImage([])
    setRoomTypeImageIndex(0)
    setSelectedRoom_ID(null);
  }

  useEffect(()=>{
    if(eventId == null) return
   // getHotelBookings(eventId);
    getChannelTypeId()
  },[eventId])

  

  const paymentSplit = (item) => {
    let pack = { id: item.package['packageId'], price: item.package['price'] };
    const splitData = { splits: item.channel.eventPayment.filter(x => x.eventId == item.eventId ), eventId: item.eventId, pack: pack, channel: item.channel, contactList: item.channel.contacts, eventChannelId:item.id };
    setSplitData(splitData);
    setSplitFlag(true);
  }

  const handleClose = () => {
    setSplitFlag(false);
  }
  
  const handlePaymentSave = () => {

  }

  const saveSplit = async(splits) => {
      
    if (splits) {
      setLoading(true);
      await axios.post('/channelAdmin/updatePaymentFromBO', splits).then(res => {
          if(res.data == true) {
          setSplitFlag(false);
          setLoading(false);
          getEventSearchList(searchState.eventId);
           ToastsStore.success('payment updated successfully');
          } else {
             ToastsStore.error('Something went wrong');
          }
      });
    }
    
  }
  const split = () => {
    return (
      <div>
        <Modal className="modal-dialog-centered" fade={false} isOpen={splitFlag} size="xg" style={{ minWidth: 900, width: '30%' }}>
          <div className="modal-header">
            <strong>{'Payment Split'}</strong>
            <button aria-label="Close" className="close" data-dismiss="modal" type="button" onClick={handleClose}>
              <span aria-hidden={true}>×</span>
            </button>
          </div>
          <div className="modal-body">
            <SplitPaymentView paymentSplit={saveSplit} splits={splitData.splits} eventId={splitData.eventId} pack={splitData.pack} eventChannelId={splitData.eventChannelId} channel={splitData.channel} contactList={splitData.contactList} />
            <div style={{ paddingLeft: '25px' }}>
            </div>
          </div>          
          <div className="modal-footer">
   
            {splitErr && <Button color="primary" type="button" onClick={() => handlePaymentSave()}>Ok</Button>}
            <Button color="secondary" data-dismiss="modal" type="button" onClick={handleClose}>Close</Button>
          </div>
        </Modal>
      </div>
    )
  }

  const getEventSearchList = async (data) => {
    console.log('getEventSearchList eventId' ,data);
    await axios.post('/channelAdmin/pendingEventChannelsList', { userId: userData.userId, eventId: searchState.eventId }).then(res => {
      let data = [];
      data.push(...res.data)
      data = data.map(x => {
        return { name: x.name.trim(), id: x.id }
      });
      let contact = [];
      console.log('contactcontact : ', res.data)
      res.data.map(x => {
        console.log('contactcontact : ', x)
        x.contacts.map(c => {
          contact.push({ name: c.firstName + ' ' + c.lastName, id: x.id, companyName: x.name.trim() });
        })
      });
     
      data.push(...contact);      
      searchState.dropdownList = data;
    });

    await axios.get('/channelAdmin/getEventList').then(res => {
      let eventNameList = res.data.currentEvents;
      console.log('eventNameList>> ', eventNameList);
      searchState.filterEvents = eventNameList;
      setSearchState({ ...searchState });
    });
    

  }

  useEffect(() => {

    (async () => {

      console.log('ihere meena ') ; 
        await EventDetailsApi();
     

    })();

  }, []);

  const EventDetailsApi = async () => {
    getEventDetails({ tabChange: true });
    getEventSearchList(searchState.eventId);
   //wait eventAuth();
  }  

  function formatDate(date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  function getDatesBetween(fromDate, toDate) {
    const startDate = new Date(fromDate);
    const endDate = new Date(toDate);
    const datesArray = [];
 
    while (startDate <= endDate) { 
      datesArray.push(formatDate(new Date(startDate)))
      startDate.setDate(startDate.getDate() + 1);
    }

    return datesArray;
}

  const getEventDetails = async (data) => {
    setLoading(true);

    if (data.tabChange) {
      let data = {
        //search: searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null,
        channelType: channelType ? channelType.map(x => x.id) : null,
        paymentStatus: paymentStatus ? paymentStatus.id : null,
        isEventCatalyst: catalystStatus?.id == 1 ? '1' : catalystStatus?.id == 2 ? '0' : null,
        signupstatusid: [14],
        userId: userData.userId,
        eventId: searchState.eventId,
        filter: {
        },
        page: page * rowsPerPage,
        order: isSort ? sort : false,
        limit: rowsPerPage
      }

      await axios.post('/channelAdmin/getEventByEventId', data).then(res => {
        console.log('response Data', res.data);

        if (res) {
          console.log('state.eventChannels 1= ', res.data.eventChannels)
          console.log('state.eventChannels 1= ', res.data.eventDetails)
          setEventId(res.data.eventDetails.id)
          const startDate = new Date(res.data.eventDetails.fromDate);
          const endDate = new Date(res.data.eventDetails.toDate); 
          startDate.setDate(startDate.getDate() - 3);
          endDate.setDate(endDate.getDate() + 3);
          const retArray = getDatesBetween(startDate, endDate)
          setEventDatesArray(retArray);
          console.log('state.eventChannels 1= ')
          //last booking date and last update and cancel booking date
          startDate.setDate(startDate.getDate() - 5);
          setLastBookingDate(endDate);
          setUpdateAndCancelLastDate(startDate)

          let event = res.data.eventDetails;
          setChannelPack(res.data.channelPackage);
          console.log('state.eventChannels 1= ')
          console.log('state.eventChannels 2= ')
          if (event.type != 2) {
            state.buyerPackages = event.packages.length ? event.packages.filter(x => x.isBuyer == '1') : [];
            state.sellerPackages = event.packages.length ? event.packages.filter(x => x.isSeller == '1') : [];

            state.buyerPackages.map(x => {
              x.packageDescription = x.description;
              x.meetingsAllowed = x.no_of_meeting_allowed;
              x.accessBadgeAllowed = x.no_of_access_badge;
              x.privateWebinar = x.no_of_private_webinar;
              x.firesideChat = x.no_of_fireside_chat;
              x.subscriptionToCH = x.subs_to_chPlatform;
              x.emailMarketing = x.emailMarketing;
            })

            state.sellerPackages.map(x => {
              x.packageDescription = x.description;
              x.meetingsAllowed = x.no_of_meeting_allowed;
              x.accessBadgeAllowed = x.no_of_access_badge;
              x.privateWebinar = x.no_of_private_webinar;
              x.firesideChat = x.no_of_fireside_chat;
              x.subscriptionToCH = x.subs_to_chPlatform;
              x.emailMarketing = x.emailMarketing;
            })
          }
          console.log('state.eventChannels 3= ')
          state.buyerOption = state.buyerOption.map(x => {
            if (event.buyers.split(',').map(x => Number(x)).includes(x.id))
              x.status = true;
            return x;
          })
          console.log('state.eventChannels 4= ')
          state.sellerOption = state.sellerOption.map(x => {
            if (event.sellers.split(',').map(x => Number(x)).includes(x.id))
              x.status = true;
            return x;
          })
          state.master = res.data.eventChannels.rows;
          state.eventChannels = res.data.eventChannels.rows.slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage);

          state.total = res.data.eventChannels.count;
          state.channelTypeCount = res.data.channelTypeCount;

          res.data.usersCount.map(x => {
            if (x.status == '1') {
              state.usersCount1[x.channelType].approved = x.count;
            } else {
              state.usersCount1[x.channelType].not_approved = x.count;
            }
          })

          let dummy = [];
          for (const keys in state.usersCount1) {
            dummy.push(state.usersCount1[keys]);
          }

          state.usersCount = dummy;
          setState({ ...state });

        }

        setLoading(false);
      }).catch(err => setLoading(false));
    }
    else {
      let search = searchState.search ? searchState.search[0] ? searchState.search[0].id : null : null;

      if (search) {
        state.eventChannels = state.master.filter(x => x.channelId == search);

      }
      else if (channelType.length > 0) {
        let filter = state.master.filter(x => { return channelType.map(x => x.id).includes(x.channel.channelTypeId); })

        state.eventChannels = filter.slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage);
      }

      else
        state.eventChannels = state.master.slice(page * rowsPerPage, rowsPerPage + page * rowsPerPage);

      setState({ ...state });
      setLoading(false);
    }
  }; 

  let [isModalPopup, setModalPopup] = useState(false);


  const imgStyle = {
    width: "76%", 
    height:"110%", 
    borderRadius: "10px",
    marginTop: "27px",
    transform: "scale(1.1)",
    transition: "transform 0.3s ease",
  };

  const [editUserPackage, editUserPackageFlag] = useState(false);
  const [editUserPackageData, setEditUserPackageData] = useState({});

  const openEditUserPackage = (item) => {

    console.log('public viewss', item, state.buyerPackages);

    let packageList = [];
    if (item.eventParticipants?.find(part => part.eventId == item.eventId)?.package.isSeller == 1)
      state.sellerPackages.filter(x => x.channelType == item.channel.channelTypeId && x.isSeller == 1 && x.eventId == item.eventId).map(x => {
        packageList.push({ id: x.packageId, accessBadgeAllowed: +x.accessBadgeAllowed, name: x.packageName, packageType: x.packageType, packageUser: x.packageUser, isPublic: x.isPublic });
      })
    if (item.eventParticipants?.find(part => part.eventId == item.eventId)?.package.isBuyer == 1)
      state.buyerPackages.filter(x => x.channelType == item.channel.channelTypeId && x.isBuyer == 1 && x.eventId == item.eventId).map(x => {
        packageList.push({ id: x.packageId, accessBadgeAllowed: +x.accessBadgeAllowed, name: x.packageName, packageType: x.packageType, packageUser: x.packageUser, isPublic: x.isPublic });
      })

    const chPackIds = channelPackIds?.filter(x => x.channelId == item.channelId).length ? channelPackIds.filter(x => x.channelId == item.channelId) : [];

    let allPackage = packageList.filter(x => (x.isPublic == '1'));
    let data = {
      channelId: item.channelId,
      contactId: item.contactId,
      eventId: item.eventId,
      cType: item.channel.channelTypeId,
      pType: item.eventParticipants?.find(part => part.eventId == item.eventId)?.package.packageType,
      packageId: item.eventParticipants.find(part => part.eventId == item.eventId)?.packageId,
      isRegisteredUser: item.registeredBy == item.contactId,
      selectedPackage: chPackIds.length > 0 ? [...packageList.filter(x => (chPackIds?.map(p => +p.packageId).includes(+x.id))), ...allPackage] : allPackage //packageList.filter(x => (x.isPublic == '1'))

    };

    setEditUserPackageData(data);
    editUserPackageFlag(true);

  }

  console.log('state', state);

  let [page, setPage] = useState(0);
  let [rowsPerPage, setRowsPerPage] = useState(10);
  let [sort, setSort] = useState('');
  let [isSort, setisSort] = useState(false);
  let[hotelSearch, setHotelSearch] = useState(null);
  let[serachByTable, setSerachByTable] = useState(false);


  const handleChangePage = (event, newPage) => {
    page = newPage;
    setPage(page);

    getEventDetails({ tabChange: false });

  };

  const handleChangeRowsPerPage = (event) => {
    rowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage(rowsPerPage);
    callPendingChannel({ tabChange: false });
  };

  const handleChangeSelect = (input, value) => {
 
    setSerachByTable(true)
    console.log('input : ',input)
    console.log('value : ',value)

    if(input == 'eventfilter'){
      searchState.eventId = +value.id;      
      setSearchState({ ...searchState });
      getEventDetails({ tabChange: true });
      getEventSearchList(+value.id);
    }    
    if (input == 'search') {
      searchState.search = [value]
      setSearchState({ ...searchState });
      callPendingChannel({ tabChange: false });
  
    }

    if (input == 'channelType') {
      // console.log("ctypes:", value);
      channelType = value;
      setChannelType(value);
      callPendingChannel({ tabChange: false });
    }

    if (input == 'paymentStatus') {
      console.log("paymentStatus:", value);
      paymentStatus = value;
      setPaymentStatus(value);
      callPendingChannel({ tabChange: true });
    }

  }

  const callPendingChannel = (data) => {
    // closeDrawer();
    page = 0;
    setPage(page);
    getEventDetails(data);
  }
  const setSorting = (data) => {
    isSort = true;
    setisSort(isSort);
    if (data != sort) {
      sort = data;
      setSort(sort);
      callPendingChannel({ tabChange: false });
    }
  }

  const clearSorting = () => {
    isSort = false;
    setisSort(isSort);
    callPendingChannel();
  }


const openModalPopUp = (contactList,eventChannel, ch_Id, id, eventId, packageId) => {
  modalPopValue.message = 'You want to Set Paid This Payment';
  modalPopValue.title = "Event Payment";
  //modalPopValue.second_message = packageName ? "Selected Package - " + packageName : '';
  modalPopValue.value = { contactList: contactList, eventChannel: eventChannel, multiEventApproval: null, channelId: ch_Id, id: id, eventId: eventId, packageId: packageId, eventInfo: state.eventInfo, registeredEventId: [] };
  
  modalPopValue.popUpId = 11;
  setModalPopValue({ ...modalPopValue })
  open = true;
  setModalOpen(open);
}
  const handleSave = () => {

  }
  
  

  const User = {
    renderer: (row, rowIndex) => {
      console.log('User rowuser', row, rowIndex);
      return (
        <div style={{ width: '100%' }}>
           {open &&
            <Dialog paymentSplit={saveSplit} open={open} value={modalPopValue} handleSave={handleSave} handleClose={handleClose}></Dialog>}
          {row.channel.contacts.length && row.channel.contacts.map((x, i) => {
          let eventcoordinate = (row?.eventcoordinate.length > 0 && row?.eventcoordinate[0].contactId)?row.eventcoordinate[0].contactId:null;

         console.log('eventcoordinateeventcoordinateeventcoordinateeventcoordinate =: ', row.id);  

          if(x.eventParticipants.length > 0 || eventcoordinate == x.contactId) {
            let paymentRecords = row.channel.eventPayment.filter(payment => payment.eventId == row.eventId && payment.contactId == x.contactId);
           
            let notifyTags = (eventcoordinate)?(eventcoordinate == x.contactId)?'E':'P':null;
            let notifyTags_backgroundColor = (eventcoordinate)?(eventcoordinate == x.contactId)?'red':'green':null;
            let payment = '';
              if (paymentRecords.length) {
                if (paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length == 0) //.paymentstatus == '1')
                  payment = 'Paid';
                else if (paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length && paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length)//(row.paymentstatus == '0' && row.paymentMethod == '2')
                  payment = 'Partially Paid' //'waiting for approval'
                else if (paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length == 0 && paymentRecords.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length) //(row.paymentstatus!=1)
                  payment = 'Pending';
              }
              else
                payment = 'Free';
              return <>
              <ListGroup className="list my--2" flush key={'table' + x.contactId} style={{overflowX:"auto", flexWrap:"whitespace"}}>
                <ListGroupItem className="px-0">
                  <Row className="align-items-center"  >
                    <Col className="col-1">
                      <a
                        className="avatar rounded-circle"
                        href="#pablo"
                        onClick={e => e.preventDefault()}
                      >
                        <img onError={(event) => { event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                          alt="..."
                          src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                        />
                      </a>
                    </Col>
                    <Col className="col-lg-2 ml--2">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>

                        {x.firstName + ' ' + x.lastName} 

                            <span style={{ backgroundColor:notifyTags_backgroundColor, borderRadius:"3px", padding:'1px 5px 2px 5px', color:'white'}} id={'notifyTags' + x.contactId}>
                          {notifyTags}
                        </span>
                        <UncontrolledTooltip delay={0} target={'notifyTags' + x.contactId}>
                        {notifyTags == 'E' ? 'Event Coordinator': 'Participant' }
                      </UncontrolledTooltip>
                      </p>
                      <small>{x.email}</small>
                    </Col>

                    <Col className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.jobTitle}
                      </p>
                    </Col>                  
                    <Col className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {/* {x.eventPayment.splitId} */}
                      </p>
                    </Col>

                    <Col className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {/* {x.user?.role?.roleId == '1' ? 'Account Owner' : x.user?.role?.roleId == '2' ? 'Admin' : 'Primary'} */}
                        {(x.eventParticipants?.find(pack => pack.eventId == row.eventId)?.package?.packageType == 1 && x.eventParticipants?.find(pack => pack.eventId == row.eventId)?.package?.packageUser == 1) ? 'Primary' : 'Secondary'}
                      </p>
                    </Col>
                   
                    <Col className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {x.eventParticipants?.find(pack => pack.eventId == row.eventId)?.package?.packageName}
                        {/* {x.isShared == '0' ? 'Confidential' : 'Shared'} */}
                      </p>
                    </Col>
                    {
                      x.eventParticipants.length > 0 && <Col className="col-lg-2">
                        <p className="mb-0" style={{ fontSize: '0.9rem', marginLeft:"3rem" }}>
                          {Moment(x.eventParticipants.find(pack => pack.eventId == row.eventId)?.updatedAt).format('d/MM/YYYY')}
                        </p>
                      </Col>
                    }
                    <Col className="col-lg-1">
                      <p className="mb-0" style={{ fontSize: '0.7rem' }}>
                        {payment == "Paid" ?
                          <span style={{ backgroundColor: '#4caf50', color: '#fff', borderRadius:"15px", padding:"7px", fontWeight: "bold" }} variant="filled" size="large" > {payment} </span>: payment == "Free" ? <span style={{ backgroundColor: '#2c9db7', color: '#fff', borderRadius:"15px", padding:"7px", fontWeight: "bold"  }} variant="filled" size="large"> {payment}</span> :
                            <span style={{ backgroundColor: '#fb6340', color: '#fff', borderRadius:"15px", padding:"7px", fontWeight: "bold" }} variant="filled" size="large">{payment}</span>}

                      </p>
                    </Col>
                    <Col className="col-lg-1">
                    <button id={'SplitPaymentView' + row.channelId} onClick={() => paymentSplit(row)} className="btn btn-icon btn-danger btn-sm" variant="contained" style={{ "background": "red" }}> Details </button>
                    </Col>
                  </Row>
                </ListGroupItem>

              </ListGroup></>
            }
          })
          }
        </div>
      )
    },
    className: 'bgrow'
  };

  const channelTypeList = { "2": "VENDOR", "3": "DISTRIBUTOR", "4": "RETAILER", "5": "RESELLER" };
  
  const getChannelTypeId = async () => {
    const apiUrl = 'common/getChannelTypeForSignUp/';
    let channelTypeOption =  await axios.get(apiUrl);
    console.log('channelTypchannelTypeOptione => ',channelTypeOption)
    let channelTypeList1 = {};
    let i = 0;
    channelTypeOption.data.map(x => {
      console.log('channelTypeId => ',x.channelTypeId)
      console.log('channelType => ',x.channelType)
      channelTypeList1[+x.channelTypeId] = x.channelType
      i = i + 1
      
    });
   console.log('channelTypeList1 ', channelTypeList1)
  }
  return (
    <div>
      {splitFlag && split()}    

      <SimpleHeader name="Manage-Event" parentName="Event" />
      <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />    
      {loading ? <Spinner /> : <>
      

        {/* Table for channel */}
        <Container className="mt--3" fluid>
          <Row>
            <div className="col">
              <Card>
                <CardHeader>
                  <Row>
                    <Col lg='2'>
                      <h3 className="mb-0 d-inline">Event Channels</h3>
                    </Col>
                    <Col lg='10'>
                      <Nav className="event-nav" style={{ float: "right", width: "100%", display: "initial" }}>
                      <NavItem className="pl-2 " style={{  width: "19%", display: "inline-block" }}>
                            <SelectAutocomplete
                              id="eventfilter"
                              type="text"
                              label="Event"
                              name="eventfilter"
                              className="d-inline-block"
                              style={{ width: '148%' }}
                              options={searchState.filterEvents}
                              placeholder="filter events"
                              disableCloseOnSelect
                              // defaultValue={searchState.search?searchState.search[0]:null}
                              control={control}
                              input='eventfilter'
                              value={searchState.filterEventsSelect ? searchState.filterEventsSelect[0] : null}
                              handleChangeSelect={handleChangeSelect}
                            />
                        </NavItem>
                        <NavItem className="pl-2 " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          {/* style={listing === '/admin/mynetwork'? {top : '0.2rem'}:{top : '3.5rem'}} */}
                          <SelectAutocomplete
                            id="search"
                            type="text"
                            label="search user"
                            name="search"
                            options={searchState.dropdownList}
                            placeholder="search..."
                            disableCloseOnSelect
                            defaultValue={searchState.search ? searchState.search[0] : null}
                            control={control}
                            input='search'
                            value={searchState.search ? searchState.search[0] : null}
                            handleChangeSelect={handleChangeSelect}
                          />
                        </NavItem>
                        <NavItem className="pl-2  chan-type " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          <FormAutocomplete
                            id="channel_type"
                            name="channel_type"
                            label="Channel Type"
                            options={company_type_Option}
                            placeholder="Channel Type ..."
                            multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            // open={true}
                            input='channelType'
                            getOptionSelected={(options, value) => options.name === value.name}
                            value={channelType}
                            handleChangeSelect={handleChangeSelect}
                            // popupIcon=''
                            disablePortal={true}
                            // ListboxProps={{ style: { maxHeight: '16rem' } }}
                            size='small'
                          />
                        </NavItem>

                        <NavItem className="pl-2  chan-type " style={{ float: "right", width: "19%", display: "inline-block" }}>
                          <FormAutocomplete
                            id="payment_status"
                            name="payment_status"
                            label="Payment Status"
                            options={payment_status_Option}
                            placeholder="Payment Status ..."
                            // multiple
                            disableCloseOnSelect
                            limitTags={1}
                            control={control}
                            // open={true}
                            input='paymentStatus'
                            getOptionSelected={(options, value) => options.name === value.name}
                            value={paymentStatus}
                            handleChangeSelect={handleChangeSelect}
                            // popupIcon=''
                            disablePortal={true}
                            // ListboxProps={{ style: { maxHeight: '16rem' } }}
                            size='small'
                          />
                        </NavItem>
                      </Nav>
                    </Col>
                  </Row>

                  {/* <p className="text-sm mb-0">
                    This is an exmaple of data table using the well known
                    react-bootstrap-table2 plugin. This is a minimal setup in
                    order to get started fast.
                  </p> */}
                </CardHeader>
                {<ToolkitProvider
                  ClassName="align-items-center table-flush" responsive
                  data={state.eventChannels}
                  keyField="channel.channelId"
                  columns={[

                    {
                      dataField: "channel.companyName",
                      text: "Company Name",
                      sort: true,
                      onSort: (field, order) => {

                        if (order === 'desc') {
                          state.master = state.master.sort((a, b) => (a.channel.companyName.toLowerCase() > b.channel.companyName.toLowerCase()) ? -1 : b.channel.companyName.toLowerCase() > a.channel.companyName.toLowerCase() ? 1 : 0)
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }
                        else {
                          state.master = state.master.sort((a, b) => (a.channel.companyName.toLowerCase() < b.channel.companyName.toLowerCase()) ? -1 : b.channel.companyName.toLowerCase() < a.channel.companyName.toLowerCase() ? 1 : 0);
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }

                      }

                    },
                    {
                      dataField: "channel.channelId",
                      text: "Company Name",
                      sort: true,
                      formatter: (rowContent, row) => {
                        return(
                          <>{rowContent}</>
                        )
                      }

                    },
                    {
                      dataField: "channel.contacts",
                      text: "Users",
                      formatter: (rowContent, row) => {
                        console.log('row contents', rowContent)
                        return (
                          <>
                            <div className="avatar-group">
                              {rowContent.length && rowContent.map((x, i) => <><a
                                className="avatar avatar-sm rounded-circle"
                                href="#pablo"
                                id={'easytool' + x.contactId}
                                onClick={e => e.preventDefault()}
                              >
                                <img onError={(event) => { axios.post('/media/errorLog', { url: event.target.src, documentTypeId: 1, name: null }); event.target.setAttribute("src", "http://d2maj8ifjo1lti.cloudfront.net/document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"); }}
                                  alt="..."
                                  src={x.document?.documentPath ? x.document.documentPath.includes(process.env.REACT_APP_CDN_URL) ? x.document.documentPath : process.env.REACT_APP_CDN_URL + x.document.documentPath : process.env.REACT_APP_CDN_URL + 'document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg'}//{require("assets/img/theme/team-1.jpg")}
                                />
                              </a>
                                <UncontrolledTooltip delay={0} target={'easytool' + x.contactId}>
                                  {x.firstName + ' ' + x.lastName}
                                </UncontrolledTooltip></>)}

                            </div>
                          </>
                        )
                      }
                    },
                    {
                      dataField: "channel.channelTypeId",
                      text: "Channel Type",
                      //sort: true,
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {rowContent == 2 && "Vendor"}
                            {rowContent == 3 && "Distributor"}
                            {rowContent == 4 && "Retailer"}
                            {rowContent == 5 && "Reseller"}
                          </>
                        )
                      }
                    },

                    // {
                    //   dataField: "package.packageName",
                    //   text: "Package",

                    // },

                    {
                      dataField: "approvalStatus",
                      text: "Approval",
                      sort: true,
                      onSort: (field, order) => {

                        if (order === 'desc') {
                          state.master = state.master.sort((a, b) => (a.approvalStatus > b.approvalStatus) ? -1 : b.approvalStatus > a.approvalStatus ? 1 : 0)
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }
                        else {
                          state.master = state.master.sort((a, b) => (a.approvalStatus < b.approvalStatus) ? -1 : b.approvalStatus < a.approvalStatus ? 1 : 0);
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }

                      },
                      formatter: (rowContent, row) => {
                        return (
                          <>
                            {(rowContent == 0 || rowContent === null) && "Pending"}
                            {rowContent == 1 && "Approved"}
                            {/* {rowContent == 4 && "Retailer"}
                            {rowContent == 5 && "Reseller"} */}
                          </>
                        )
                      }
                    },

                    {
                      dataField: "paymentstatus",
                      text: "Payment Details",
                      headerStyle: { paddingLeft: "5rem" },
                      style:{paddingLeft:"5rem"},
                      sort: true,
                      onSort: (field, order) => {

                        if (order === 'desc') {
                          state.master = state.master.sort((a, b) => (a.paymentstatus > b.paymentstatus) ? -1 : b.paymentstatus > a.paymentstatus ? 1 : 0)
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }
                        else {
                          state.master = state.master.sort((a, b) => (a.paymentstatus < b.paymentstatus) ? -1 : b.paymentstatus < a.paymentstatus ? 1 : 0);
                          setState({ ...state });
                          callPendingChannel({ tabChange: false });

                        }

                      },

                      formatter: (rowContent, row) => {
                        let payment = '';
                        if (row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length == 0) //.paymentstatus == '1')
                          payment = 'paid';
                        else if (row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length && row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length)//(row.paymentstatus == '0' && row.paymentMethod == '2')
                          payment = 'Partially Paid' //'waiting for approval'
                        else if (row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 1).length == 0 && row.channel.eventPayment.filter(x => x.eventId == row.eventId && x.paymentStatus == 0).length) //(row.paymentstatus!=1)
                          payment = 'Pending';
                        else
                          payment = 'Free';

                        return (
                          <>
                            {payment}
                          </>
                        )
                      }
                    }
                                   
                  ]}
                >
                  {props => (
                    <>
                      <div className="py-2 table-responsive">
                        <div
                          id="datatable-basic_filter"
                          className="dataTables_filter px-4 mb-2"
                        >

                        </div>
                        <BootstrapTable
                          {...props.baseProps}
                          expandRow={User}
                          bootstrap4={true}
                          // pagination={pagination}
                          bordered={false}
                          hover={true}

                          rowClasses='pointer'
                        />
                      </div>

                      <TablePagination
                        component="div"
                        count={state.total}
                        page={page}
                        onChangePage={handleChangePage}
                        rowsPerPage={rowsPerPage}
                        onChangeRowsPerPage={handleChangeRowsPerPage}
                        ActionsComponent={TablePaginationActions}
                      />
                    </>
                  )}


                </ToolkitProvider>}
              </Card>
            </div>
          </Row>
        </Container>

      </>}
    </div>
  )
};
export default EventPayment;



const company_type_Option = [
  { name: "Vendor", id: '2' },
  { name: "Distributor", id: '3' },
  { name: "Retailer", id: '4' },
  { name: "Reseller", id: '5' }
]

const payment_status_Option = [

  { name: "Pending", id: 1 },
  { name: "Paid", id: 2 },
  { name: "Partially Paid", id: 3 }

]

const catalyst_status_Option = [
  { name: "Already Sent", id: 1 },
  { name: "Ready to Send", id: 2 }
]

const hotel_status = [
  {name:"Booked", id:1},
  {name:"Not Booked", id:2},
  {name:"All", id:3}
]