import React, { useEffect, useState } from 'react'
import {
  Card, CardBody, CardHeader, Container
} from "reactstrap";
import BootstrapTable from "react-bootstrap-table-next";
import ToolkitProvider, { Search } from "react-bootstrap-table2-toolkit";
import { Dialog } from "views/Hoc/Dialog/GlobalDialog";


import 'date-fns';

import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import axios from 'axios';

import 'react-bootstrap-timezone-picker/dist/react-bootstrap-timezone-picker.min.css';
import { EventSchema } from './validation';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { SelectAutocomplete, FormSelect, FormInput, FormTextArea} from "_components/FormElements/FormInput";
import { ModalSpinner } from 'views/Hoc/Spinner';
import SetRoom from './SetRoom';
import { id } from 'date-fns/locale';


const EventSocialShare = ({ eventId }) => {
  const [hotelName, setHotelName] = useState([]);
  const [selectHotel, setSelectHotel] = useState(null);
  const [loading, setLoading] = useState(false);
  const [shareDataForEvent, setShareDataForEvent] = useState([])
  const [tblHotelByEventCountryId, setTblHotelByEventCountryId] = useState(null)
  let [isModalPopup, setModalPopup] = useState(false);
  let [modalPopValue, setModalPopValue] = useState({ message: null, title: null, value: null, popUpId: null });

  let [state, setState] = useState({ sharetype: null, title: null, content: null, eventtitle:null });
  const [deleteId, setDeleteId] = useState(null)
  const [roomModal, setRoomModal] = useState(false);
  const [hotelId, setHotelId] = useState(null);
  const [hotelBookData, setHotelBookingData] = useState([]);

  const [hotelNameForSetupRoom, setHotelNameForSetupRoom] = useState(null);
  const [hotelCountryForSetupRoom, sethotelCountryForSetupRoom] = useState(null);
  const [hotelAddressForSetupRoom, sethotelAddressForSetupRoom] = useState(null);

  const { register, handleSubmit, control, errors, watch, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(EventSchema),
  });

  useEffect(() => {
    //getHotelName()
    getSocialShareEvent()
  }, [])

  const getHotelName = async () => {
    // setLoading(true);
    // await axios.get(`channelAdmin/getHotelsByEventCountry/${country}`)
    //   .then((res) => {
    //     const hotelData = res.data.map((item) => ({ id: item.id, name: item.hotelName }));
    //     setHotelName(hotelData)
    //     setLoading(false)
    //   })
    //   .catch((err) => console.log(err))
  }

  const getSocialShareEvent = async () => {
    setLoading(true);
    await axios.get(`channelAdmin/getSocialShareText/${eventId}`)
      .then((res) => {
        console.log('res.datares.data ',res.data)
        setShareDataForEvent(res.data);
      //  sessionStorage.setItem('hotelBookingData', JSON.stringify(res.data.hotelBookingData));
        setLoading(false)
      })
      .catch((err) => console.log(err))
  }


 const saveContent = async() => {
   let data = { title : state.eventtitle,
                shareText : state.shareText,
                eventId:eventId
              }

    setLoading(true);
    await axios.post('channelAdmin/saveSocialShareText', data)
      .then((res) => {
        getSocialShareEvent();
        setLoading(false)
      })
      .catch((err) => console.log(err))          
  }
  const handleFilter = (input, value) => {

  console.log('handleFilter',input, value)
  let shareId = 1
     if(value == 'LinkedIN'){
      shareId = 1
     }
     let addupdateCnt = shareDataForEvent.map((x) => { 
      console.log(x.shareTypeId ,'==' ,shareId)
        if(x.shareTypeId == shareId) 
        {
          console.log(x)
          setState({ ...state, shareText: x.shateText, eventtitle: x.title });
        }
      });
     //console.log('popdata :', addupdateCnt)
  
   
  }

  const handlessChange = (input)=> (e) => {
    setState({ ...state, [input]: e.target.value });
  }

  return (
    <Container className="col-lg-9 col-md-9 col-sm-12  " fluid>
      {/* {
        roomModal == true && <SetRoom hotelName={hotelNameForSetupRoom} hotelCountry={hotelCountryForSetupRoom} hotelAddress={hotelAddressForSetupRoom} roomModal={roomModal} setRoomModal={setRoomModal} hotelId={hotelId} eventId={eventId} tblHotelByEventCountryId={tblHotelByEventCountryId} eventFromDate={eventFromDate} eventToDate={eventToDate} />
      } */}
      <Card>
        <CardHeader>
          <h2 style={{ textAlign: "center", color: "#2ca5cd" }}>Social Share Content</h2>
        </CardHeader>
        <CardBody>
          <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
          
          <div className="row">
            {/* <button type="submit" className="btn btn-primary ml-4" onClick={addShareContentEvent}>Add Share Text</button> */}
            <div className="col-lg-6 mt-3">
              <div className="form-group">
              <div>
                <FormSelect
                  id="Profileofdist"
                  //defaultstate={["fdsafds","fsdafds"]}
                    label="Share Type"
                  placeholder="Select Share Type"
                  options={["LinkedIN","FaceBook"]}
                  register={register}
                  error={errors.postalcode}
                 handleChangeSelect={handleFilter}
                
                />
            </div>
            </div>
            </div>
            <div className="col-lg-6 mt-3">
            <div className="form-group">
              Title{state.eventtitle}
              <FormInput
                id="eventtitle"
                type="text"
                name="eventtitle"
                label="Event Title*"
                register={register}
                defaultValue={state.eventtitle}
                error={errors.eventtitle}
                value={state.eventtitle}
               onChange={handlessChange('eventtitle',)}
            />
            </div>
            </div>
            <div className="col-lg-12 mt-6">
            <div className="form-group">
              <FormTextArea
                  className="buytext2 w-100"
                  id="shareText"
                  name="shareText"
                  placeholder="Tell us about your share content *"
                onChange={handlessChange('shareText')}
                  value={state.shareText}
                />
            </div>
            </div>
            <div className="col-lg-12 mt-6">
            <div className="form-group">
              <button onClick={saveContent}>Save</button>
            </div>
            </div>
           
          </div>

          {loading == true && <ModalSpinner />}

          <div style={{ display: loading == true ? "none" : 'block' }}>
            {
              shareDataForEvent.length > 0 ?
                <ToolkitProvider
                  data={shareDataForEvent}
                  keyField="id"
                  columns={[

                    // {
                    //   dataField: "title",
                    //   sort: true,
                    //   text: "Title"
                    // },
                    {
                      dataField: "shareTypeId",
                      text: "Share Name",
                      sort: true,

                      formatter: (row) => (
                        <div>
                          {row}</div>
                      )

                    },

                    {
                      dataField: "title",
                      text: "Title",
                      sort: true,
                      formatter: (row) => (
                        <>
                          <div>{row}</div>
                          
                        </>
                      )
                    },
                    {
                      dataField: "shateText",
                      text: "Content",
                      sort: true,
                      formatter: (row) => (
                        <>
                          <div>{row}</div>
                          
                        </>
                      )
                    }

                  

                   

                  ]}
                  search
                >
                  {props => (
                    <div className="table-responsive">
                      <div
                        id="datatable-basic_filter"
                        className="dataTables_filter mb-2"
                        style={{ display: "flex", flexDirection: "row", alignItems: "center", justifyContent: "space-between" }}
                      >
                        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", paddingTop: "10px", paddingBottom: "10px" }}>


                        </div>
                      </div>


                      <BootstrapTable
                        bordered={false}
                        classes={`table-striped table-hover`}
                        {...props.baseProps}
                        bootstrap4={true}
                      />
                    </div>
                  )}
                </ToolkitProvider>
                :

                <h4 style={{ textAlign: "center", marginTop: "4rem" }}>Please Add Social Share content for Event</h4>
            }



          </div>

        </CardBody>
      </Card>
    </Container>
  )
}
const sharetypeOption = [
  { value: "1", label: "LinkedIN" },
  { value: "2", label: "FaceBook" },
];

export default EventSocialShare

