import React, { useEffect, useState } from 'react';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';
import { FormTextArea, FormInput, FormAutocomplete, SingleAutocompleteGrouping, FormRadio, FormCheckBox, FormAutocompleteGrouping } from "_components/FormElements/FormInput";
import { useForm } from "react-hook-form";
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import Moment from 'moment';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import {
    MuiPickersUtilsProvider,
    KeyboardTimePicker, KeyboardDatePicker,
  } from '@material-ui/pickers';
  import DateFnsUtils from '@date-io/date-fns';
import Select from '@mui/material/Select';
import TextareaAutosize from '@mui/material/TextareaAutosize';

import {
    FormControl,
    InputAdornment,
} from "@material-ui/core";
import {
    Button, Modal
} from "reactstrap";
import Chip from '@mui/material/Chip';

const payOption = [{ id: 1, name: "Full Amount", checked: false }, { id: 2, name: "Split", checked: true }]

const payPeriod = [{ id: 1, name: "Immediate", value: 0 }, { id: 2, name: "45 Days", value: 45 }, { id: 3, name: "90 Days", value: 90 }]

export const SplitPaymentView = (props) => {

    const { pack, paymentSplit, splits,eventId, eventChannel, contactList, channel, contact, popupId, eventChannelId } = props;
    var today = Moment();
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const [paymentOption, setPaymentOption] = useState(payOption);
    const [periodList, setPeriodList] = useState([]);//{ id: 1, period: payPeriod, percent: null, selectedPeriod: 1 }, { id: 2, period: payPeriod, percent: null, selectedPeriod: null }]);
    const [totalSplit, setSplit] = useState(2);

    const [paidAmount, setPaidAmt] = useState();
    const [balAmount, setBalAmt] = useState();
    let [comments, setComments] = useState('');

    const [paidFlag, setPaidFlag] = useState(true);
    let [paymentNow, setPaymentNow] = useState({});
    let [pennyinvoiceId, setPennyinvoiceId] = useState({});
   // let [cncnt, setCncnt] = useState(0);

    let [cnfirmErr, setCnfirmErr] = useState(false);
    let [show, setShow] = useState(0);    
    const [checkReceipt, setCheckReceipt] = useState([]);
    let [errors, setErrors] = useState([]);
       const [splitErr, setSplitErr] = useState(false);
    let cncnt = 0;
    
    useEffect(() => {

        splits.sort((a, b) => a.splitId - b.splitId)
       
        let list = [];let paidamt=0;let balance=0;
        for (var i = 0; i < splits.length;i++) {
            console.log('checking payment Receipt :' , splits[i]);
            if(splits[i].paymentStatus==1){
            paidamt=paidamt+parseInt(splits[i].splitAmount);
            }else{
                balance=balance+parseInt(splits[i].splitAmount);
            }
            pennyinvoiceId[splits[i].id] = splits[i].pennyInvoiceId;
            list.push({channelId:splits[i].channelId,contactId:splits[i].contactId,paymentStatus:splits[i].paymentStatus,id: splits[i].splitId, percent: splits[i].percentage, selectedPeriod: null,paymentDate:Moment(splits[i].paymentDate).format('MM/DD/YYYY'), pid: splits[i].id ,  splitAmount: splits[i].splitAmount, receiptId: splits[i].pennyInvoiceId });

        }
        setPennyinvoiceId(pennyinvoiceId);
        setPaidAmt(paidamt);setBalAmt(balance);
    
        setPeriodList(list);
        setSplit(splits.length);      
    }, [])



  

    const confirmPayment = async() => {        
        let saveFlag = true;
       
        if(comments == ''){
            saveFlag = false;
            console.log('comments are emo')
            setCnfirmErr(true)
       } else {  setCnfirmErr(false) }
        await channel.eventPayment.map(res => {       
        if((paymentNow['split_'+res.id] == true && pennyinvoiceId[res.id] == '') || (paymentNow['split_'+res.id] == true && pennyinvoiceId[res.id] == undefined && res.pennyInvoiceId == null) ) {
             errors['e_'+res.id] = true             
            saveFlag =false;
        } else {
            errors['e_'+res.id] = false     
        }
        setErrors(errors)
       })
   
       console.log( 'errors ' ,  errors )
        if(saveFlag) {
            let splits = {
                splits : [paymentNow],
                pennyinvoiceId:[pennyinvoiceId],
                comments: comments,
                payments:channel.eventPayment,
                eventChannelId:eventChannelId
            }
            console.log('splitssplits return :', splits)
            paymentSplit(splits);
        }
    }

    const changePaymentOption = (selected, value, id) => {        
        paymentNow[selected] = value
        
   
        periodList.map(period => {
            if(period.pid == id) {
                // console.log(pennyinvoiceId[id], ' pennyinvoiceId  : ', period.receiptId)
                //  let receiptCheck = ((pennyinvoiceId[id] == '' && period.receiptId != null) ||(pennyinvoiceId[id] == undefined && period.receiptId == null) )?true:false;
                //  errors[id] = receiptCheck
                //  console.log('errors',errors);
                //  setErrors(errors)
            }
        });
       
        show = (value)?parseInt(show)+parseInt(1):parseInt(show)-parseInt(1);
       
        setPaymentNow(paymentNow); 
        setShow(show)
         console.log('cncnt = ', paymentNow)   
    }

    const handleSplitReceipt = (id, value) =>   {
        errors['e_'+id] = false
       // console.log(errors, 's error : e_',id ,value)
        setErrors(errors)
        pennyinvoiceId[id] = (value)?value:'';  
        setPennyinvoiceId(pennyinvoiceId)
        console.log('pennyinvoiceIdpennyinvoiceId = ' , pennyinvoiceId)      
        
        // periodList.map(period => {
        //     if(period.pid == id) {
        //        // console.log(value, ' hai  : ', period.receiptId)
                
        //         let receiptCheck = ((value == '' && period.receiptId != null) || (value == undefined && period.receiptId == null) )?true:false;
        //         errors['e_'+id] = receiptCheck
        //         ///console.log(errors, 's error : e_',id ,receiptCheck)
        //         setErrors(errors)
        //     }
        // });

    }

    return (
        <>
            <Box
                sx={{
                    border: '2px dashed #2c9db7',
                    // height: 320,

                    marginLeft: '25px',

                    width: 780,
                    '& .actions': {
                        color: 'text.secondary',
                    },
                    '& .textPrimary': {
                        color: 'text.primary',
                    },
                }}
            >
                <div className="container" style={{ marginTop: 5, padding: '10px' }}>

                    <div className="row">
                        <div className="col-lg-2">
                            <b>Package Amount</b>
                        </div>
                        <div className="col-lg-2">
                            <Chip label={`€ ${pack.price}`} style={{ backgroundColor: 'blue', color: '#fff' }} variant="filled" size="large" />
                        </div>

                        <div className="col-lg-2">
                            <b>Paid Amount</b>
                        </div>
                        <div className="col-lg-2">
                            <Chip label={`€ ${paidAmount}`} style={{ backgroundColor: 'green', color: '#fff' }} variant="filled" size="large" />
                        </div>

                        <div className="col-lg-2">
                            <b>Balance Amount</b>
                        </div>
                        <div className="col-lg-2">
                            <Chip label={`€ ${balAmount}`} style={{ backgroundColor: 'red', color: '#fff' }} variant="filled" size="large" />
                        </div>                        
                    </div>

                    {((popupId == 3 && !paidFlag) || popupId != 3) && <>
                        

                        
                            <div className="row">
                                <div className="col-lg-1" style={{ marginBottom: '20px', paddingRight: 0 }}>
                                    Split
                                </div>
                                <div className="col-lg-2" >                                     
                                    Percentage                                          
                                </div>
                                <div className="col-lg-2" >                                     
                                    Amount                                          
                                </div>
                                <div className="col-lg-2" >                                     
                                    Status                                          
                                </div>
                                <div className="col-lg-2">
                                    Due Date
                                </div>
                                <div className="col-lg-2">
                                    Receipt URL
                                </div>
                            </div> 
                      
                        {(periodList.length > 0 && paymentOption.find(x => x.id == 2).checked) &&                           
                                periodList.map(period => {
                                    return <>
                                    <div className="row">
                                       <div className="col-lg-1" style={{ marginBottom: '20px', paddingRight: 0 }}>
                                        {period.id}
                                       
                                        {period.paymentStatus == "0" && <Checkbox
                                                checked={paymentNow[period.pid]}
                                                // color="warning"
                                                onChange={(e) => { changePaymentOption('split_'+period.pid, e.target.checked ? true : false, period.pid) }}
                                                inputProps={{ 'aria-label': 'controlled' }}
                                            />}
                                            
                                        </div>
                                        <div className="col-lg-2" >                                     
                                            {Math.round(period.percent)+'%'}                                                
                                        </div>
                                        <div className="col-lg-2" >                                     
                                            {period.splitAmount}                                               
                                        </div>
                                        <div className="col-lg-2" >                                     
                                            {(period.paymentStatus == "1"?'Paid':'Pending')}                                           
                                        </div>
                                        <div className="col-lg-2">
                                            {!period.paymentDate? null:periodList.find(x => x.id == period.id)?.paymentDate}
                                        </div>
                                        <div className="col-lg-3">
                                      
                                        <TextField style={{ width: '90px' }}
                                        InputLabelProps={{ shrink: true }}
                                        id={period.pid}
                                        label="pennyinvoiceId"
                                        type="text"
                                        defaultValue={period.receiptId}  
                                        error={errors['e_'+period.pid]?'Please Enter receipt Id':''}
                                        name={period.pid}
                                        //value={pennyinvoiceId[period.pid]}
                                        onChange={(e) => handleSplitReceipt(period.pid, e.target.value)}
                                    />

                                        {/* <FormInput
                                        className=""
                                        id="pennyinvoiceId"
                                        label="Penny Receipt Id"
                                       // type="number"
                                        name='pennyinvoiceId'
                                        style={{width:"200px"}}
                                        // register={register}
                                        // error={errors[period.pid]?'Please Enter receipt Id':''}
                                        defaultValue={period.receiptId}                                 
                                       // value={pennyinvoiceId[period.pid]}
                                        onChange={(e) => handleSplitReceipt(period.pid, e.target.value)}

                                        // autoComplete="current-password"
                                        variant="outlined"
                                        /> */}
                                        </div>
                                    </div>                                        
                                    </>
                                })
                                
                            }
                            <div className="row">
                            {cnfirmErr && <div style={{ color:"red", fontSize:"11px", width: '100%', textAlign:"right", display:"block"  }}> Please enter valid comments </div> }
                                <TextareaAutosize
                                    maxRows={4}
                                    InputLabelProps={{ shrink: true }}
                                    placeholder="Reason For Offline Payment"
                                    aria-label="maximum height" 
                                    value={comments}
                                    onChange={(e) => setComments(e.target.value)}
                                    style={{ width: '100%', height: 100,  }}
                                />
                            
                            </div>
                    </>}
                </div>
            </Box>
            {/* <div style={{ paddingLeft: '25px' }}>
                {(totalSplit == 1 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Please go with Full Amount</p></div>}

                {(totalSplit == 7 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Maximum 6 splits are allowed</p></div>}

                {(totalSplit == 0 && paymentOption.find(x => x.id == 2).checked) && <div className="row"><p style={{ color: "red" }}>Atleast minimum 2 splits are must</p></div>}

                {splitErr && <><br /> <div className="row"><p style={{ color: "red" }}>Please make it proper split with all data</p></div></>}

                {lastSplitErr && <><br /> <div className="row"><p style={{ color: "red" }}>Last Split Percentage Should be atleast 1</p></div></>}
            </div> */}
            <div style={{ paddingLeft: '25px' }}>
             {splitErr && <><br /> <div className="row"><p style={{ color: "red" }}>Please make it proper split with all data</p></div></>}
             </div>
              <div className="modal-footer" style={{ textAlign: 'center', paddingBottom: '0' }}>
              {(show > 0) &&
                <Button style={{ margin: 'auto' }} color="primary" type="button" onClick={() => confirmPayment()}>ConfirmPayment </Button> }
            </div>
        </>
    );
}