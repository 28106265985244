import React, { useState, useEffect, useRef } from "react";
import {
  Card, CardBody, CardHeader, Container,
  InputGroupAddon,
  InputGroupText,
  InputGroup, Col, Row
} from "reactstrap";
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { FormInput, FormAutocomplete, FormTextArea } from "_components/FormElements/FormInput";
import { PdfCard, ImageCard } from "views/Hoc/PdfCard";
import { otherUserSchema } from '../validation';
import { FormControl, InputAdornment } from '@material-ui/core';
import axios from 'axios';
import ImageLibrary from 'views/Hoc/Dialog/ImageLibrary';
import { ModalSpinner, Spinner } from 'views/Hoc/Spinner';
import MultiUpload from "views/Hoc/MultiUpload";
import { commonService } from '_services/common.service';
import { ImageLibraryMultipleUploads } from 'views/Hoc/Dialog/ImageLibraryMultipleUploads';
import { MultiSelectImages } from 'views/Hoc/Dialog/MultiSelectImages';
import { BannerUploader } from 'views/Hoc/ProfileUpload';
import { ToastsContainer, ToastsStore } from 'react-toasts';
import { DocumentName } from 'views/Hoc/Dialog/DocumentName';
import AnnualTurnOver from "views/Hoc/annualTurnOver";
import { history } from '_helpers/history';

var _ = require('lodash');


const ManageOtherUser = (props) => {

  const [prodImage, setProdImage] = useState(false);
  let [comImage, setComImage] = useState(false);
  const [loading, setLoading] = useState(false);
  const { register, handleSubmit, control, errors, watch, setValue, formState } = useForm({
    mode: 'onBlur',
    resolver: yupResolver(otherUserSchema),
  });

  const [brandName, setBrandName] = useState('');
  const [openadd, openAddnew] = useState(false);
  const [openDist, setOpenDist] = useState(false);
  const [openRetailer, setOpenRetailer] = useState(false);

  const [initLoad, setInitLoad] = useState(true);
  const [initialVideo, setInitialVideo] = useState('');
  const [open, setOpen] = useState(false);
  const [image, setImage] = useState('');
  const [imageSrc, setImageSrc] = useState('');
  const [bannerId, setBannerId] = useState('');
  let [namePopup, setNamePopup] = useState(false);
  let [docName, setDocName] = useState(null);
  let [docId, setDocId] = useState(null);
  const [isAnnualTurn, setAnnualTurn] = useState(false);
  const [isAdd, setIsAdd] = useState(false);
  const [checkMailId, setCheckMail] = useState(0);
  const [state, setState] = useState({
    yearOfEstablishment: '',
    companyRegNumber: '',
    companyLogo: null,
    tweetPitch: '',   
    documentId: '',
    sourcing: '',
    companyDesc: "",   
    companyName: '',
    legalAddress: '',
    country: '',
    countryList: [],
    zipCode: '',
    city: '',
    phoneNumber1: '',
    phoneNumber2: '',
    mobile: '',
    email: '',
    websiteUrl: '',
    linkedIn: '',
    insta: '',
    facebook: '',
    twitter: '',
    tradeUrls: '',
    cerificateName: '',
    isVat: '',
    regAddressId: '',
    billAddressId: '',
    b2bList: [{ id: Math.random(), value: '' }],
    deleted_B2B: [],
    certificateId: '',
 
    social_linkedin_id: null,
    social_instagram_id: null,
    social_twitter_id: null,
    social_facebook_id: null,  
    deletedDocuments: [],   
    no_of_skus: null,
    storeCount: null,
    domesticRevenue: null,
    internationalRev: null,   
    annualTurnOver: '',
    annualTurnOverList: [],
    keyTags: [],
    deletedTags: [],
    linkedIn_pre: 'https://www.linkedin.com/company/',
    insta_pre: 'https://www.instagram.com/',
    facebook_pre: 'https://www.facebook.com/',
    twitter_pre: 'https://twitter.com/',
    speciality: [],
    specialityOptions: [],
    isOEM: '3',
    firstname:'',
    lastname:'',
    emailId:'',
    password:null,
    confirm_password:null,
    channelTypeList : [],
    channelType:null,
    no_of_staffs:null,
    staffCnt:null,
    channelId:null,
    contactId:null,
    userId:null,
    jobposition:''
  })
  let [profileTypeId, setProfileTypeId] = useState(null);
  const [mediaState, setMediaState] = useState({
    productBrochures: [],
    bannerImages: []
  });



  const watchAll = watch();//'tradeUrls',

  
  var userData = JSON.parse(localStorage.getItem('CHuser'))?.userData;
 
  //console.log('errors lst :', errors);
  const myRef = useRef(null);

  //console.log('userDAta', userData)
  useEffect(() => {
    setIsAdd((userData.channelId)?true:false);
    getCompanyData();
  }, [])

  const getCompanyData = async () => {
    setLoading(true);
    const countryUrl = '/common/getSignup/2';
    const channelTypeUrl = '/common/getChannelAllType';
    const getUserDetails = 'setting/companyuser';  
    
    //   state['country'] = {countryCode :"GB",id:"46",isVat: "0", name:"United Kingdom",
    //   phoneCode :"+44", priority :6};
    // setState({ ...state });
     

    if(userData.channelId) {
    await axios.get(getUserDetails+'/'+userData.channelId).then(res => {

   
        let channelData = res.data.result;
      //  console.log('channelData ----> ', channelData)
        //console.log(' res.data : ',res.data);
        state.channelId = channelData.channelId;
      //  console.log(' res.data 1: ',state);
        state.yearOfEstablishment = channelData?.channelDetail.estYear;
        //console.log(' res.data 2: ',state);
        state.companyLogo= channelData?.channelDetail?.channelLogo?.documentPath;
        //console.log(' res.data 3: ',state);
        state.tweetPitch= channelData?.channelDetail?.detailDesc; 
        //console.log(' res.data 4: ',state);
        state.companyDes= channelData?.channelDetail?.companyDesc; 
        //console.log(' res.data 5: ',state);
        state.companyName= channelData?.companyName;
        //console.log(' res.data 6: ',state);
        state.legalAddress= channelData?.regAddress?.address;
        //console.log(' res.data 7: ',state);
        state.country= {id:+channelData?.country?.countryId, name:channelData?.country?.country,
          isVat:channelData?.country.isVat, countryCode:channelData?.country?.isoCode, phoneCode:channelData?.country?.phoneCode,priority:channelData?.country?.priority
        };
        state.firstname= channelData?.contact?.firstName; 
        state.lastname= channelData?.contact?.lastName; 
        state.contactId = channelData?.contact?.contactId;

        state.emailId= channelData?.user[0]?.email; 
        state.password= channelData?.user?.password; 
        state.userId= channelData?.user[0]?.userId; 
        state.confirm_password= channelData?.user?.password; 
        state.jobposition = channelData?.contact?.jobTitle;

       // console.log('channelData == ', channelData.companyName)
     //  console.log(' res.data : ',state);
        setState({ ...state });
        
        state.zipCode= channelData.regAddress.postalCode;
        state.city= channelData.regAddress.city;
        state.channelDetailId = +channelData.channelDetail.channelDetailId;
        state.mobile= channelData.channelDetail.mobileNo; 
        state.email= channelData.companyMail; 
        state.websiteUrl= channelData.channelDetail.webSiteUrl;        
        state.no_of_staffs= channelData.channelDetail?.empCntRange; 
        state.staffCnt= channelData.channelDetail?.empCnt; 
        state.regAddressId= channelData.regAddressId; 
        state.billAddressId= channelData.billAddressId; 
        state.is_same_address= 1; 
        setState({ ...state });
        state.annualTurnOverList= res.data.turnOver;        
        
       
        setState({ ...state });
        if (res.data?.channelB2B?.length) {
          state.b2bList = (res.data.channelB2B.map(x =>
            ({ channel_B2B_id: x.channelB2BId, value: x.channelB2B })
          ))
        }
        else {
          state.b2bList = [{ id: Math.random(), value: '' }];
        }

        if (channelData?.channelSocial?.length) {
          channelData.channelSocial.map(x => {
            if (x.socialSiteId == 1) {
              state.social_linkedin_id = x.channelSocialLinkId;
              //state.linkedIn = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
              //console.log('social site ID: ', state.linkedIn);
              let splitlinked = x.channelSocialLink?.split(state.linkedIn_pre)
              state.linkedIn = (splitlinked.length > 1)?splitlinked[1]:splitlinked[0]
            }
            if (x.socialSiteId == 3) {
              state.social_instagram_id = x.channelSocialLinkId;
              //state.insta = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
              let splitInsta = x.channelSocialLink?.split(state.insta_pre)
              state.insta = (splitInsta.length > 1)?splitInsta[1]:splitInsta[0]
            }

            if (x.socialSiteId == 4) {
              state.social_twitter_id = x.channelSocialLinkId;
              //state.twitter = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
              let splitTwitter = x.channelSocialLink?.split(state.twitter_pre)
              state.twitter = (splitTwitter.length > 1)?splitTwitter[1]:splitTwitter[0]
            }
            if (x.socialSiteId == 5) {
              state.social_facebook_id = x.channelSocialLinkId;
              //state.facebook = x.channelSocialLink?.split(/[?#]/)[0].replace(/\/$/, "").split('/').splice(-1, 1)[0];
              let splitFacebook = x.channelSocialLink?.split(state.facebook_pre)
              state.facebook = (splitFacebook.length > 1)?splitFacebook[1]:splitFacebook[0]
            }

           
          });

         
        }
       
        setState({ ...state });
        mediaState.productBrochures = [];          
          if (res.data?.channelPdf?.length) {
            res.data.channelPdf.map(x => {                       
              mediaState.productBrochures.push({ channelDocumentId: x.channelDocumentId ? +x.channelDocumentId : null, documentId: +x.documentId, document: x.document, name: x.name});
            })
          }
        if (res.data.banner) {

          setImageSrc(res.data.bannerImg?.documentPath);
          setBannerId(res.data.bannerImg?.documentId);

          
          setImageSrc(res.data.banner?.documentPath);
          setBannerId(res.data.banner?.documentId);
          setMediaState({ ...mediaState, bannerImages: [{ image: res.data.banner?.documentPath, id: res.data.banner?.documentId }] });
          
      }
        setLoading(false);
      }).catch(err => {
       // console.log('Error while getting channel Type', err);
        setLoading(false);
      });
    }

    await axios.get(channelTypeUrl).then(res => {
      state.channelTypeList = res.data;
      state.channelType = (userData?.channelTypeId)?res.data.filter(x => x.id == userData?.channelTypeId)[0]:null
      setState({ ...state });      
    }).catch(err => {
      //console.log('Error while getting channel Type')
    });  
    

    await axios.get(countryUrl).then(res => {
      state.countryList = res.data.country;
      setState({ ...state });
    }).catch(err => {
      commonService.postService({ err: JSON.stringify(err), reqUrl: window.location.pathname, service: '/common/getSignup/' }, '/error')
    }); 

    setLoading(false);
  }

  const pdfDelete = (data) => {
    let filteredState = mediaState.productBrochures.filter(item => {
      if (item.documentId == data.documentId && item.channelDocumentId) {
        let temp = state.deletedDocuments;
        temp.push(+item.channelDocumentId);
        setState({ ...state, deletedDocuments: temp });
      }
      return item.documentId != data.documentId
    });
    if (filteredState) {
      setMediaState({ ...mediaState, productBrochures: filteredState });
      //console.log('after delete', mediaState);
    }
  }



  const openAdd = async (input, value) => {
    //console.log('opneadd',opneadd);
    await setBrandName(input);
    openAddnew(true);
  }

  const getCompanyLogo = (resImagePath) => {
    console.log('resImagePath : ', resImagePath);
    setState({ ...state, companyLogo: resImagePath.document.documentPath, documentId: resImagePath.documentId });
  };

  const [companyError, setCompanyError] = useState(false);
  useEffect(() => {
    setCompanyError(false);
  }, []);

  //save company like other forms
  const saveCompDist = async () => {    
    console.log('called  : ', 'companyRegistration')
 
    try {
      
      let bannerImage = mediaState?.bannerImages?.length ? [].concat(mediaState.bannerImages.map(x => {
        return { image: x.image, id: x.id ? x.id : '' }
      })) : [];
      
      let productBrochure = {
        productpdf: mediaState?.productBrochures?.length ? [].concat(mediaState.productBrochures.map(x => {
          return { channelDocumentId: x.channelDocumentId ? +x.channelDocumentId : null, documentId: +x.documentId, document: x.document.documentUrl, documentName: x.name };
        })) : [{ document: 'null' }]
      }

      let media = {
        channel_document: productBrochure.productpdf,
        bannerImage: bannerImage
      }

      // if (state?.deletedDocuments?.length)
      //   media = Object.assign(media, { deleted_channel_documents: state.deletedDocuments });
      
      let socialProfile = {
        social_linkedin_url: state.linkedIn,
        social_instagram_url: state.insta,
        social_linkedin_id: state.social_linkedin_id,
        social_googleplus_id: null,
        social_instagram_id: state.social_instagram_id,
        contact_linkedin_id: null,
        social_twitter_url: state.twitter,
        social_twitter_id: state.social_twitter_id,
        social_facebook_url: state.facebook,
        social_facebook_id: state.social_facebook_id,
      };

      if (state.b2bList.length) {
        state.b2bList = [].concat(state.b2bList.map(x => {

          if (x.channel_B2B_id && x.value)
            return { B2B_url: x.value, channel_B2B_id: x.channel_B2B_id }
          else if (x.channel_B2B_id && !x.value) {
            state.deleted_B2B.push(x.channel_B2B_id);
            return null;
          } else if (x.value && !x.channel_B2B_id) {
            return { B2B_url: x.value }
          }

        }));
        state.b2bList = state.b2bList.filter((el) => el != null);
        socialProfile = Object.assign(socialProfile, { deleted_B2B: state.deleted_B2B.length ? [... new Set(state.deleted_B2B)] : [null] });
        if (state.b2bList.length)
          socialProfile = Object.assign(socialProfile, { B2B_url: state.b2bList });
      }



    
      let companyRegistration = {
        company_logo_url: +state.documentId,
        mode: state.channelId?'edit':'add',
        company_url: state.companyLogo ? process.env.REACT_APP_CDN_URL + state.companyLogo : process.env.REACT_APP_CDN_URL +
        "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg",
        company_name: state.companyName,
        is_same_address: 0,
        reg_address_id: +state.regAddressId,
        reg_country_id: +state.country.id,
        reg_address: state.legalAddress,
        reg_postal_code: state.zipCode,
        reg_city: state.city,
        emailId:state.emailId,
        mobile:state.mobile,
        channelType:state.channelType, 
        channelId:+state.channelId,  
        userId:+state.userId,
        channelDetailId:+state.channelDetailId,
        profileTypeId:profileTypeId 
      }
      
      let tradeInformation = {
        company_about: state.tweetPitch,      
        companyDesc: state.companyDesc ? state.companyDesc : null,       
      }
      console.log('companyRegistration : ', companyRegistration)

      let contactInfo = {
        firstname: state.firstname,
        lastname:state.lastname,
        contactId:state.contactId,
        jobposition:state.jobposition        
      }
    
    
      let facts = {
        year_of_estabilishment: +state.yearOfEstablishment,
        no_of_skus: +state.no_of_skus,
      }

      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.no_of_staffs))
        facts['employeesRange'] = state.no_of_staffs
      else
        facts['employeesCount'] = +state.no_of_staffs

      if (/[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(state.annualTurnOver))
        facts['annualTurnoverRange'] = state.annualTurnOver
      else
        facts['annualTurnover'] = +state.annualTurnOver

     
      let marketing = {
        socialProfile: socialProfile,
        company_website_url: state.websiteUrl,
        bannerImage: bannerImage,
      }    
      
      let document = {
        channel_document: productBrochure.productpdf,    
      }

    if (state.deletedDocuments?.length)
        document = Object.assign(document, { deleted_channel_documents: state.deletedDocuments });
      

      let finalData = {
        companyRegistration: companyRegistration,
        tradeInformation: tradeInformation,
        marketing: marketing,
        facts: facts,
        channel_document: (productBrochure?.productpdf)?productBrochure.productpdf:null,        
        annualTurnOverList: state.annualTurnOverList,
        contact:contactInfo
      }

      //console.log('finalData == ', finalData);
      await commonService.postService(finalData, '/channelAdmin/other_user_profile').then(res => {
        console.log(res.data)
        if(res.data == 'companyNameAlreadyExist'){
          ToastsStore.warning('Company Name already exist!');
          // console.log('erroncompany', err);
        } else {
        ToastsStore.success('Saved Successfully!');
        let mailData = {
          contactId: +res.data.channelId, 
          emailId: state.emailId, 
          profileurl: process.env.REACT_APP_LOCALSITE
        }
          axios.post('mailerservice/OtherUser_Confirmation_Mail', mailData);
          setLoading(false);
          setInitLoad(true)    
          history.push('/admin/CompanyListing');    
        }       
      }).catch(err => {
        ToastsStore.error('Something Went Wrong!');
       // console.log('erroncompany', err);
        setLoading(false);
      })

      
    }  catch (err) {
      ToastsStore.error('Something Went Wrong!');
   //   console.log('erroncompany', err);
      setLoading(false);

    }
  }


  const handleChange = input => e => {
  //  console.log('handleChange input = meena ',input)
    if (input == 'tradeUrls') {
      let b2b = [...state.b2bList];
     // console.log('b2b', b2b, e.target.value, e.target);
      b2b[e.target.id].value = e.target.value;
      setState({ ...state, b2bList: b2b })
    }
    else if (input == 'tweetPitch') {
      setState({ ...state, [input]: e.target.value.slice(0, 280) });
    }
    else if (input == 'companyDesc') {
      setState({ ...state, [input]: e.target.value.slice(0, 2000) });
    }
    else if (input == "linkedIn" || input == "twitter" || input == "facebook" || input == "insta") {
      setState({ ...state, [input]: e.target.value.slice(state[input + '_pre'].length,) });
      errors[input] = false;
    }
    else if (input == 'annualTurnOver' || input == 'no_of_staffs') {
      let temp = input == 'annualTurnOver' ? 'turnOver' : 'staffCnt';
      document.getElementById(temp).value = null;
      errors[temp] = null;
      setState({ ...state, [input]: e.target.value });
    } else if (input == 'turnOver' || input == 'staffCnt') {
      let temp = input == 'turnOver' ? 'annualTurnOver' : 'no_of_staffs';
      setState({ ...state, [temp]: e.target.value });
    } else if (input == 'isOEM') {
      setState({ ...state, [input]: e.target.value });
      if (props.ratingId)
        props.setUserData({ ...userData, [input]: e.target.value });
    }
    else {
      // if (input == 'vatNumber') {
      //   setVatCheck(0);
      // }
      //console.log(input, 'value = ', e.target.value)
      setState({ ...state, [input]: e.target.value });

    }
   // console.log("addssss  ", e.target.value, e.target);

  }



  const handleChangeSelect = async (input, value) => {
    if (input == 'country') {
      setLoading(true);
      await commonService
        .getServices("/setting/getVat/" + value?.id)
        .then((res) => {
          state.isVat = res.data.country[0].isVat;
        })
        .catch((err) => {
          commonService.postService(
            {
              err: JSON.stringify(err),
              reqUrl: window.location.pathname,
              service: "/setting/getVat",
            },
            "/error"
          );
        });
      state[input] = value;
      setState({ ...state });
      setLoading(false);
    }
    if (input == 'channelType') {
      state[input] = value;
      //console.log(' channeltype :', value);

      await commonService.postService({'channelTypeId': value.id}, '/common/getProfileType').then(res => {
        //console.log(res.data)
        if(res.data){
          setProfileTypeId(res.data.profileTypeId);
        }
      });

      setState({ ...state });
    }

  }

  

  //console.log('media', mediaState);
  
  //let typeId = userData?.channelTypeId;
  
 

  const handleChangeFileSelect = async (input, value, filename) => {
   // console.log('input = ', input)
    if (input === 'productBrochures') {
      //console.log('brochures');
      let temp = mediaState.productBrochures;
      let newFiles = value.filter(file => !mediaState.productBrochures.find(f => f.documentId === file.documentId));

     // console.log('temp meena', temp, temp.length + newFiles.length)
      if (temp.length + newFiles.length <= 5) {
        temp.push(...newFiles);
        setMediaState({ ...mediaState, [input]: temp });
      }
      else {
        console.log('Maximum 5 pdf`s, Accepts PDF only, File Size Should Not Exceed 5 mb');
      }
    } else {
      let res = await handleOnDrop(input, value, filename);
      if (res) {
      //  console.log('res', res);
        setMediaState({ ...mediaState, [input]: value })
      };
    }
  }

  const handleChangeFileDelete = async (input, fileToBeRemoved) => {
    let filteredState = await mediaState[input].filter(item => item.filename != fileToBeRemoved);
    if (filteredState) {
      setMediaState({ ...mediaState, [input]: filteredState });
      //console.log('after delete', mediaState);
    }
  }

  //functions for image
  const handleOnDrop = async (input, data, file_name) => {
    if (data) {
      setLoading(true);
     // console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
        //console.log('file', file)
       // console.log('reader.result', reader.result)
        selectImg(input, reader.result, file_name)
        //setOpen(false);          
      };

    }
  }

  const handleChangeBanner = event => {
    const data = event.target.files[0];
   // console.log('files', data)

    if (data && data['type'].split('/')[0] === 'image') {
     // console.log('datat', data);
      let file = data;
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onloadend = () => {
      //  console.log('file', file)
      //  console.log('reader.result', reader.result)
        setImage(
          reader.result
        );
       // console.log('image', image)
        //setOpen(false);          
      };

    }

  };

  const selectBannerImg = async (data) => {
    if (data) {
      setImage('');
      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);
      setLoading(true);
      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + 'banner');
      //console.log('resImagePath', resImagePath);
      if (resImagePath) {
        setImageSrc(resImagePath.data.url);
        setBannerId('');
        setMediaState({ ...mediaState, 'bannerImages': [{ image: resImagePath.data.url }] });
        setLoading(false);
      }

    }

  }

  function dataURLtoFile(dataurl, filename) {

    var arr = dataurl.split(','),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    return new File([u8arr], filename, { type: mime });
  }

  const addNewB2B = () => {
    setState({ ...state, b2bList: [...state.b2bList, { id: Math.random(), value: '' }] });
  }

  const delB2B = (id) => {
   // console.log('id', id);
    let del = state.deleted_B2B;
    state.b2bList.map((x, i) => {
      if (id == i && x.channel_B2B_id)
        del.push(x.channel_B2B_id);
    })
    let temp = state.b2bList.filter((r, i) => i !== id);
    setState({ ...state, b2bList: temp, deleted_B2B: del });
  }

  const selectImg = async (input, data, file_name) => {
    if (data.length > 0) {

      //converting base64 string to file format.
      var random = Math.random();
      var fileName = random + '.png';
      var file = dataURLtoFile(data, fileName);
      const formData = new FormData();
      formData.append('file', file);

      let aws_folder = input === 'bannerImages' ? 'banner' : '';


      const resImagePath = await commonService.fileUploadService(formData, '/mediaupload/upload/' + aws_folder);
      if (resImagePath) {
        if (input === 'bannerImages') {
          let temp = mediaState.bannerImages;
          temp.push({ image: resImagePath.data.url, filename: file_name })
          setMediaState({ ...mediaState, [input]: temp });
        }
        setLoading(false);

      } else {
        loading = false;
        setLoading(loading);
      }

    }
    // setOpen(false);
  }
  const editName = (item) => {
    docName = item.name;
    setDocName(docName);
    docId = item.documentId;
    setDocId(docId);
    setNamePopup(true);
  }
  const docNameChange = async (docId, name) => {
   // console.log('media', mediaState.productBrochures);
  //  console.log(docId, name);
    mediaState.productBrochures.map(x => {
      if (parseInt(x.documentId) === parseInt(docId))
        x.name = name;
    })
    namePopupClose();
  }
  const namePopupClose = () => {
    setNamePopup(false);
  };
 
  const closeTurnOver = () => {
    setAnnualTurn(false);
  };

  const saveTurnOver = async (value) => {
    setAnnualTurn(false);
    value.map(x => {
      x.annualYear = new Date().getFullYear() - (+x.annualYear - 1);
    })
    state.annualTurnOverList = value;
    setState({ ...state });
    //console.log("saveproduct:", value);

  };

  const handleChangemail = input => e => {
    var re = /\S+@\S+\.\S+/;
    state.emailId = e.target.value;
    setState({ ...state });
    if (re.test(e.target.value)){
      state.emailId = e.target.value;
      setState({ ...state });
      checkMail(e.target.value);
    } else {
      //setCheckMail(1)
      errors.emailId = true;
      errors.emailError = 'Invalid EmailId';
    }
  }

  const checkMail = () => {
    const apiUrl = 'account/checkmail/'+state.emailId;
     // console.log('apiUrl',apiUrl);
      axios.get(apiUrl).then(res => {
      //  console.log('res', res.data)
        if(res.data.isavialble == true){
          setCheckMail(0);
          errors.email = false;
          //errors.emailError = 'Invalid EmailId';
        }
        else{
          console.log('error already taken mail')
          setCheckMail(1)
        }
      })
  }

  const save = async () => {
    saveCompDist();
  }

  return (
    <div className="main-content">
    <ToastsContainer position="top_right" autoClose={5000} store={ToastsStore} />
    {isAnnualTurn &&
      <AnnualTurnOver open={isAnnualTurn} currentValue={state.annualTurnOverList} handleClose={closeTurnOver} handleSave={saveTurnOver} />}
      
      <DocumentName isOpen={namePopup} handleClose={namePopupClose} isEdit={true} handleSave={docNameChange} name={docName} docId={docId} />    
      <Container className="mt-4 col-lg-10 col-md-9 col-sm-12  " fluid>
      {comImage &&
          <MultiSelectImages
            id={1}
            isOpen={comImage}
            multiple={true}
            limit={5}
            acceptedFiles={['image/jpeg', 'image/png', 'image/gif']}
            getBrochuresIds={handleChangeFileSelect}
            input="companyImages" aws_folderName="companyImages"
            handleClose={() => {
              comImage = false;
              setComImage(comImage)
            }}
            documentIds={mediaState?.companyImages.length > 0 ? mediaState.companyImages.map(x => parseInt(x.documentId)) : []}
            channelData={userData}
          />
        }
        <form noValidate onSubmit={handleSubmit(save)}>
          {loading && <div style={{ top: '50%', left: '50%', zIndex: '1000', }}><Spinner /></div>}
          <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="frmUser" ref={register} name="frmUser" value="frmUser" control={control} />


          <Card>
              <CardHeader className="col-lg-12 justify-content-center">
                <h3 className="text-center">Channel Type </h3>                
              </CardHeader>
              <CardBody > 
                    <FormControl variant="outlined" style={{ width: "50%" }}>
                      <FormAutocomplete
                        id="channelType"
                        name="channelType"
                        label="Channel Type"
                        options={state.channelTypeList}
                        placeholder="Select Channel Type"
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}
                        input='channelType'
                        value={state.channelType}
                        getOptionSelected={(options, value) => options.name === value.name}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Channel Type'}
                        error={errors.channelType}
                        defaultValue={state.channelType}
                      />
                    </FormControl>
                  </CardBody> 
          </Card>

          {state.channelType != null &&<div>
            <Card>
              <CardHeader className="col-lg-12 justify-content-center">
             <h3 className="text-center">Company Registration</h3>
                  {/*  <p className="text-center">Enter Company legal details.</p>> */}                
              </CardHeader>
           
            
           
               <CardBody > 
              <div className="row">
                <div className="col-sm-12 col-lg-12">
                <h4 style={{width:'100%'}}>Company Logo *</h4>
                  <div className="row">
                    <div className="col-sm-4 col-md-4 col-xl-2 col-lg-3 resp-logo">
                      <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyLogo" name="companyLogo" ref={register} value={state.companyLogo} />
                      {!errors.companyLogo || state.companyLogo ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select Company Logo</span>}
                      <div>

                        <ImageLibrary getDocumentId={getCompanyLogo} aws_folderName='CompanyLogo' id={1} size={1}
                          img={state.companyLogo ? process.env.REACT_APP_CDN_URL + state.companyLogo : process.env.REACT_APP_CDN_URL +
                            "document/company/logo/zF71l5KG_1548153498529_1548153484685.jpg"}
                          channelData={userData}
                        />
                      </div>

                    </div>
                    <div className="col-sm-8 col-md-8 col-xl-10 col-g-9 mt-5">
                      <span>Accepts Jpeg / Jpg / Png Only</span>
                      <br></br>
                      <span>(Max size allowed 200 Kb)</span>
                      <span className="d-block">(* Image resolution should be 200 * 200)</span>
                    </div>                
                   
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 mt-2">                 
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="companyName" ref={register} name="companyName" value={state.companyName} defaultValue={state.companyName} control={control} />
                
                  <FormInput className="comp-name mt-4" id="outlined-search" 
                  name="companyName" label="Company Legal Name" 
                  type="text" 
                  variant="outlined" 
                  value={state.companyName}
                    onChange={handleChange('companyName')}                    
                    error={errors.companyName}
                    defaultValue={state.companyName}
                  />
                </div>
              </div>

              <div className="row">
                <div className="col-lg-6 mt-3">                  
                  <FormInput className="comp-name mt-2 addrs" id="outlined-search" label="Registered/Legal Address" type="text" variant="outlined" value={state.legalAddress}
                    onChange={handleChange('legalAddress')}
                    multiline
                  />

                  
                </div>

                <div className="col-lg-6 mt-4 ">
                  <div className="row">                   
                    <FormControl variant="outlined" style={{ width: "100%" }}>
                      <FormAutocomplete
                        id="country"
                        name="country"
                        label="Country"
                        options={state.countryList}
                        placeholder="Select Country"
                        disableCloseOnSelect
                        blurOnSelect
                        limitTags={1}
                        control={control}
                        input='country'
                        value={state.country}
                        getOptionSelected={(options, value) => options.name === value.name}
                        handleChangeSelect={handleChangeSelect}
                        errMessage={'Please Select Country'}
                        error={errors.country}
                        defaultValue={state.country}
                      />
                    </FormControl> 
                  </div>
                  <div className="row">
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="zipCode" ref={register} name="zipCode" value={state.zipCode} defaultValue={state.zipCode} control={control} />
                
                  <FormInput className="comp-name mt-4" id="outlined-search" 
                  name="zipCode" label="Zip/Pincode *" 
                  type="text" 
                  variant="outlined" 
                  value={state.zipCode}
                  onChange={handleChange('zipCode')}                    
                  error={errors.zipCode}
                  defaultValue={state.zipCode}
                  />
                  </div>
                  <div className="row">
                  
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="city" ref={register} name="city" value={state.city} defaultValue={state.city} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="city" label="City *" 
                type="text" 
                variant="outlined" 
                value={state.city}
                onChange={handleChange('city')}                    
                error={errors.city}
                defaultValue={state.city}
                />

                  </div>
                </div>
              </div>
            </CardBody>
            </Card>
          
          <Card>
            <CardHeader>
              <h3 className="text-center">Contact Information</h3>             
            </CardHeader>

            <CardBody>
              <Row>
                <Col lg="6">
                
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="emailId" ref={register} name="emailId" value={state.emailId} defaultValue={state.emailId} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="emailId" label="Email Id *" 
                type="text" 
                variant="outlined" 
                value={state.emailId}
                onChange={handleChange('emailId')}                    
                error={errors.emailId}
                defaultValue={state.emailId}
                />

                </Col>
                <Col lg="6">
                    
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="mobile" ref={register} name="mobile" value={state.mobile} defaultValue={state.mobile} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="mobile" label="Mobile Number" 
                type="text" 
                variant="outlined" 
                value={state.mobile}
                onChange={handleChange('mobile')}                    
                error={errors.mobile}
                defaultValue={state.mobile}
                />
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                  
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="firstname" ref={register} name="firstname" value={state.firstname} defaultValue={state.firstname} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="firstname" label="FirstName *"
                type="text" 
                variant="outlined" 
                value={state.firstname}
                onChange={handleChange('firstname')}                    
                error={errors.firstname}
                defaultValue={state.firstname}
                />
                </Col>

                <Col lg="6">
                  
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="lastname" ref={register} name="lastname" value={state.lastname} defaultValue={state.lastname} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="lastname" label="LastName *"
                type="text" 
                variant="outlined" 
                value={state.lastname}
                onChange={handleChange('lastname')}                    
                error={errors.lastname}
                defaultValue={state.lastname}
                />
                
                </Col>
              </Row>

              <Row>
                <Col lg="6">
                              
                <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="jobposition" ref={register} name="jobposition" value={state.jobposition} defaultValue={state.jobposition} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="jobposition" label="jobposition *"
                type="text" 
                variant="outlined" 
                value={state.jobposition}
                onChange={handleChange('jobposition')}                    
                error={errors.jobposition}
                defaultValue={state.jobposition}
                />
                </Col>

                <Col lg="6">
                  
                </Col>
              </Row>
                            
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h3 className="text-center">Trade Information</h3>
             
            </CardHeader>
            <CardBody>
              <div className="row">
                <div className="col-sm-12 mt-4 ">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="tweetPitch" name="tweetPitch" ref={register} value={state.tweetPitch} />
                  {!errors.tweetPitch || (state.tweetPitch.length > 5 && state.tweetPitch.length <= 280) ? <h3 style={{ fontSize: '1rem' }}>Your company’s short presentation Pitch (280 characters) *
                    {state.tweetPitch?.length > 0 && <span style={{ color: state.tweetPitch?.length > 280 ? "red" : "#9ea2a5" }}> remaining characters  {280 - state.tweetPitch.length}</span>}
                  </h3> :
                    <h4>Your company’s short presentation Pitch (280 characters) * <span style={{ color: '#f44336', float: 'right', fontSize: '0.75rem' }}>{
                      state.tweetPitch.length <= 1 ? 'Tweet Pitch can\'t be empty.' : state.tweetPitch.length <= 5 ? 'company short presentation must have min 5 characters' : state.tweetPitch.length > 280 ? 'company short presentation must have max 280 characters' : ''
                    }</span></h4>
                  }
                  <div className="form-group mb-0">
                    <FormTextArea
                      className="buytext2 w-100 h-auto"
                      name="note"
                      id="note"
                      rows="3"
                      type="textarea"
                      label=" "
                      placeholder="Tell us about your company background and vision -max 280 characters *"
                      value={state.tweetPitch}
                      onChange={handleChange('tweetPitch')}                    
                    />
                  </div>                 
                </div>
              </div>
                            
            </CardBody>
          </Card>

          <Card>
            <CardHeader>
              <h3 className="text-center">Marketing Information</h3>
            </CardHeader>
            <CardBody>
              <Row>
                <Col lg="12">                 
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="websiteUrl" ref={register} name="websiteUrl" value={state.websiteUrl} defaultValue={state.websiteUrl} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="websiteUrl" label="Website URL *"
                type="text" 
                variant="outlined" 
                value={state.websiteUrl}
                onChange={handleChange('websiteUrl')}                    
                error={errors.websiteUrl}
                defaultValue={state.websiteUrl}
                />

                </Col>
              </Row>

              {state.b2bList && state.b2bList.map((x, i) => <Row>
                <Col lg="11">
                  <FormInput className="comp-name mt-4" id={i} name={`tradeUrls[${i}].value`} label="Website URLs" type="text" variant="outlined"
                    value={state[`tradeUrls[${i}].value`]}
                    onChange={handleChange('tradeUrls')}
                    register={register}
                    error={errors.tradeUrls ? errors.tradeUrls[i] ? errors.tradeUrls[i].value : '' : ''}
                    defaultValue={x.value}
                  />
                </Col>
                {i == 0 ? <Col lg="1">
                  <div className="faplus">
                    <i class="fa fa-plus-circle" onClick={addNewB2B}></i>
                  </div>
                </Col> :
                  <Col lg="1">
                    <div className="faminus">
                      <i class="fa fa-minus-circle " onClick={() => delB2B(i)}></i>
                    </div>
                  </Col>}
              </Row>)}
              
              <Row>
                <Col lg="12">
                <h4 className='mt-4'>Social Profiles</h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="linkedIn" ref={register} name="linkedIn" value={state.linkedIn} defaultValue={state.linkedIn} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.linkedIn_pre + state.linkedIn} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="linkedIn1" label="Linkedin Handle" type="text" variant="outlined" value={state.linkedIn_pre + state.linkedIn}
                    onChange={handleChange('linkedIn')}                    
                    error={errors.linkedIn}
                    defaultValue={state.linkedIn_pre + state.linkedIn}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="twitter" ref={register} name="twitter" value={state.twitter} defaultValue={state.twitter} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.twitter_pre + state.twitter} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="twitter1" label="Twitter Handle" type="text" variant="outlined" value={state.twitter_pre + state.twitter}
                    onChange={handleChange('twitter')}                   
                    error={errors.twitter}
                    defaultValue={state.twitter}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="insta" ref={register} name="insta" value={state.insta} defaultValue={state.insta} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.insta_pre + state.insta} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="insta1" label="Instagram Handle" type="text" variant="outlined" value={state.insta_pre + state.insta}
                    onChange={handleChange('insta')}                    
                    error={errors.insta}
                    defaultValue={state.insta}
                  />
                </Col>
              </Row>

              <Row>
                <Col lg="12">
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="facebook" ref={register} name="facebook" value={state.facebook} defaultValue={state.facebook} control={control} />
                  <a style={{ float: 'right', marginTop: '1rem' }} href={state.facebook_pre + state.facebook} target="_blank">View Link</a>
                  <FormInput className="comp-name mt-4" id="outlined-search" name="facebook1" label="Facebook Handle" type="text" variant="outlined" value={state.facebook_pre + state.facebook}
                    onChange={handleChange('facebook')}                   
                    error={errors.facebook}
                    defaultValue={state.facebook}
                  />
                </Col>
              </Row>
              
              <Card>
                <CardBody>

                  <div className="col-lg-12 ">
                    <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Your Banner Image (this banner will be displayed on your public profile)</h4>
                    <h5 style={{ float: 'right' }}>(Accepts Jpeg / Jpg / Png Only, Image Size Should Not Exceed 5 mb)</h5>
                                      
                    <div className="form-group mt-3" style={{ height: 'auto' }}>
                      {/* <MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"/> */}
                      <BannerUploader img={bannerId ? process.env.REACT_APP_CDN_URL + imageSrc : imageSrc ? process.env.REACT_APP_CDN_URL_TEMP + imageSrc : '/static/media/Default Banner.92e53fb9.png'}
                        handleChange={handleChangeBanner} selectImg={selectBannerImg} image={image} handleCropClose={e => setImage('')}
                        channelData={userData}
                      />
                      {loading && <div style={{
                        position: 'relative', display: 'block',
                        top: '50%',
                        left: '50%',
                        zIndex: '1000',
                      }}><Spinner /></div>}
                    </div>
                    
                    <div className="text-right"><span><i>* 2048 X 400 (Use a resolution of 5:1) </i></span></div>

                  </div>
                </CardBody>
              </Card>
            </CardBody>
          </Card>
          <Card>
            <CardHeader>
              <h3 className="text-center">Documents / Resources</h3>
            </CardHeader>
            <CardBody>
              <Card>
                <CardBody>
                  <div className="col-lg-12">
                    <h4 style={{ textAlign: 'left', display: 'inline-block' }}>Official Documents</h4>
                    <h5 style={{ float: 'right' }}>(Maximum 5 Images, Accepts PDF only, File Size Should Not Exceed 5 mb)</h5>
                    
                    <div style={{ display: 'none' }}><MultiUpload multiple={true} limit={1} acceptedFiles={['image/*']} handleChangeFile={handleChangeFileSelect} handleChangeFileDelete={handleChangeFileDelete} input="bannerImages"
                      channelData={userData}
                    /></div>
                    <span onClick={() => {
                      if (mediaState.productBrochures?.length >= 5) {
                        ToastsStore.error('Maximum 5 Images');
                      } else {
                        setProdImage(true);
                      }
                    }}>
                      {
                        !mediaState.productBrochures?.length ?
                          <div className="form-group mt-3">
                            <div tabindex="0" class="MuiDropzoneArea-root">
                              <div class="MuiDropzoneArea-textContainer">
                                <p class="MuiTypography-root MuiDropzoneArea-text MuiTypography-h5">Drag and drop a file here or click</p>
                                <svg class="MuiSvgIcon-root MuiDropzoneArea-icon" focusable="false" viewBox="0 0 24 24" aria-hidden="true"><path d="M19.35 10.04C18.67 6.59 15.64 4 12 4 9.11 4 6.6 5.64 5.35 8.04 2.34 8.36 0 10.91 0 14c0 3.31 2.69 6 6 6h13c2.76 0 5-2.24 5-5 0-2.64-2.05-4.78-4.65-4.96zM14 13v4h-4v-4H7l5-5 5 5h-3z"></path></svg>
                              </div></div></div>
                          :
                          <PdfCard pdfList={mediaState.productBrochures} deletePdf={pdfDelete} editName={editName} />
                      }
                    </span>

                    {prodImage && <ImageLibraryMultipleUploads id={1} isOpen={prodImage} multiple={true} limit={1} acceptedFiles={['application/pdf']} getBrochuresIds={handleChangeFileSelect} input="productBrochures" aws_folderName="broucher"
                      handleClose={() => setProdImage(false)} documentIds={mediaState.productBrochures.length > 0 ? mediaState.productBrochures.map(x => parseInt(x.documentId)) : []}
                      channelData={userData}
                    />}
                  </div>
                </CardBody>
              </Card>
            </CardBody>
          </Card>            
          <Card>
            <CardHeader>
              <h3 className="text-center">Facts & Figures </h3>
            </CardHeader>
            <CardBody>
              <div className="col-sm-6 mt-2">
                
<input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="yearOfEstablishment" ref={register} name="yearOfEstablishment" value={state.yearOfEstablishment} defaultValue={state.yearOfEstablishment} control={control} />
                
                <FormInput className="comp-name mt-4" id="outlined-search" 
                name="yearOfEstablishment" label="yearOfEstablishment *"
                type="text" 
                variant="outlined" 
                value={state.yearOfEstablishment}
                onChange={handleChange('yearOfEstablishment')}                    
                error={errors.yearOfEstablishment}
                defaultValue={state.yearOfEstablishment}
                />

              </div>

              {/* <div className="row ">
                <div className="col-lg-8 col-md-8 mt-3 mb-3">
                  <h4 className="text-sm mb-0">Annual Turnover *
                    {!errors.annualTurnOver || state.annualTurnOverList.length ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select or Enter Annual Turnover</span>}</h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="annualTurnOver" name="annualTurnOver" ref={register} value={state.annualTurnOverList} />
                  <div className="row ">
                    <div className="col-lg-3 col-md-3 col-xs-3 mt-3 text-center res-sm ">
                      <input className="btn btn-secondary text-center btn-lg-sm-md"
                        type="button" id="emprange" name="emprange"
                        value="Add Annual TurnOver" onClick={() => { setAnnualTurn(true) }} />
                    </div>
                  </div>
                </div>               
              </div> */}

              <div className="row" >
                <div className="col-lg-8">
                  <h4 className="text-sm mb-0" >No of Staffs *{!errors.no_of_staffs || state.no_of_staffs ? '' : <span style={{ color: '#f44336', fontSize: '0.75rem' }}>   Please Select or Enter No Of Employees</span>}</h4>
                  <input type="text" style={{ position: "absolute", left: "-10000px", top: 'auto', width: '1px', height: '1px', overflow: 'hidden' }} id="no_of_staffs" ref={register} name="no_of_staffs" value={state.no_of_staffs} control={control} />
                  <div className="row" >
                    <div className="col-lg-3 col-md-3 col-sm-3 mt-3 text-center  res-sm" >
                      <input className={state.no_of_staffs == '1-10' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="1-10" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 mt-3 text-center  res-sm"  >
                      <input className={state.no_of_staffs === '11-50' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="11-50" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 mt-3 text-center res-sm" >
                      <input className={state.no_of_staffs === '51-100' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="51-100" onClick={handleChange('no_of_staffs')} />
                    </div>
                    <div className="col-lg-3 col-md-3 col-sm-3 mt-3 text-center  res-sm" >
                      <input className={state.no_of_staffs === '101-Above' ? "btn btn-secondary text-center btn-lg-sm-md  active" : "btn btn-secondary text-center btn-lg-sm-md"}
                        type="button" id="emprange" name="emprange"
                        value="101-Above" onClick={handleChange('no_of_staffs')} />
                    </div>
                  </div>
                </div>
                <div className="col-lg-4 col-sm-12 mt-4 ">
                  <div className="form-group  mt-2">
                    <FormInput
                      id="staffCnt"
                      type="number"
                      name="staffCnt"
                      label="Enter Custom Value *"
                      placeholder="Enter Your Value"
                      InputProps={{
                        startAdornment: <InputAdornment position="start"></InputAdornment>
                      }}
                      defaultValue={state.no_of_staffs}
                      value={state.staffCnt}
                      onChange={handleChange('staffCnt')}
                      control={control}
                      register={register}
                      error={errors.staffCnt}
                    />
                  </div>
                </div>


               

              </div> 
              <div className="text-center mt-4">
              <button type="submit" className="btn btn-primary" >{(state.channelId)?'Update':'Save'}</button>
                </div>        
             </CardBody>
          </Card>
          </div>}
         
        </form>
      </Container>
    </div>
  );
};

export default ManageOtherUser;

const startUp = [
  { label: 'Prototype', id: 1, value: 'PROTOTYPE' },
  { label: 'Shipping to first backers', id: 2, value: 'S_BACKER' },
  { label: 'Shipping in volume', id: 3, value: 'S_VOLUME' },
];


const radioGroupOptions = [
  {
    value: "1",
    label: "Yes",
  },
  {
    value: "0",
    label: "No",
  },

];
const radioGroupOptions1 = [
  {
    value: "1",
    label: "B2C",
  },
  {
    value: "2",
    label: "B2B",
  },
  {
    value: "3",
    label: "Both",
  }
];